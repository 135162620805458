import { navigate } from 'gatsby'

import React from 'react'
export default () => {
  React.useEffect(() => {
    navigate('/login')
  }, [])

  return null
}
